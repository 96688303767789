<template>
  <overlay-dialog
    @onBack="isShow = false"
    @onFinish="onFinish"
    @onDel="onDel"
    @onUpdate="onUpdate"
    :isUpdate="isUpdate"
    :isShow="isShow"
  >
    <template slot="topBox">
      <div class="item-box">
        <div>
          <title-item class="title-margin" text="间断时间"></title-item>
          <div class="time-box">
            <year-month-range-input ref="rangendDate"></year-month-range-input>
          </div>
        </div>
      </div>
      <van-divider />
      <div class="item-box">
        <van-cell is-link>
          <div class="row-box" @click="sheetShow = true">
            <title-item class="title-margin" text="缴费档次"></title-item>
            <span>{{ levelName }}</span>
          </div>
        </van-cell>
      </div>
      <van-divider />

      <van-action-sheet title="缴费档次" v-model="sheetShow">
        <div class="action-box">
          <div
            v-for="item in actions"
            :key="item.idx"
            v-bind:class="[
              'action-item__box',
              clickItem == item.idx ? 'clickBk' : '',
            ]"
            @click="actionSelect(item.idx)"
          >
            {{ item.name }}
          </div>
        </div>
      </van-action-sheet>
    </template>
  </overlay-dialog>
</template>

<script>
import OverlayDialog from "@/views/calc/components/OverlayDialog";
import YearMonthRangeInput from "@/components/YearMonthRangeInput";
import TitleItem from "@/views/calc/TitleItem";
import { mapMutations, mapGetters } from "vuex";
import { isTimeOverlapped, isTimeReversal } from "@/utils/time.js";
import { Cell, ActionSheet, Divider, Dialog } from "vant";
export default {
  components: {
    YearMonthRangeInput,
    TitleItem,
    OverlayDialog,
    [Cell.name]: Cell,
    [ActionSheet.name]: ActionSheet,
    [Divider.name]: Divider,
  },
  data() {
    return {
      idx: 0,
      isShow: false,
      isUpdate: false,
      sheetShow: false,
      actions: [
        { idx: 0, name: "60%", value: "0.6" },
        { idx: 1, name: "100%", value: "1" },
        { idx: 2, name: "150%", value: "1.5" },
        { idx: 3, name: "200%", value: "2" },
        { idx: 4, name: "250%", value: "2.5" },
        { idx: 5, name: "300%", value: "3" },
      ],
      clickItem: 0,
      levelName: "",
      levelValue: "",
    };
  },
  computed: {
    list: {
      get() {
        return this.getAnnuityCompany().gapList;
      },
      set(val) {
        this.setAnnuityCompany({ gapList: val });
      },
    },
  },
  methods: {
    open(item) {
      this.isUpdate = item !== undefined ? true : false;
      if (!this.isUpdate) {
        this.idx = new Date().getTime();
        this.$refs.rangendDate.clear();
      } else {
        this.idx = item.idx;
        const list = [item.begDate, item.endDate];
        this.$refs.rangendDate.setValue(list);
      }
      this.clickItem = 0;
      this.levelName = "";
      this.levelValue = "";

      this.isShow = true;
    },
    isDateError(dateList, date) {
      const { endDate } = date;

      const itemEnd = new Date(endDate);
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      if (itemEnd > new Date()) {
        Dialog.alert({
          title: "间断时间有误",
          message:
            "间断时间末尾日期不能大于当前时间：" +
            currentYear +
            "/" +
            currentMonth +
            "，请重新输入",
        });
        return true;
      }

      const timeErr = isTimeReversal(date);
      if (timeErr) {
        Dialog.alert({
          title: "日期格式不正确",
          message: "起始时间不能大于末尾时间",
        });
        return true;
      }

      const timeOverlapped = isTimeOverlapped(dateList, date);
      if (timeOverlapped != null) {
        Dialog.alert({
          title: "时间段重合",
          message:
            "输入的时间段，和已经存在的时间段" +
            timeOverlapped.begDate +
            "-" +
            timeOverlapped.endDate +
            "重合，请重新输入",
        });
        return true;
      }

      return false;
    },
    onDel() {
      let index = this.list.findIndex((item) => {
        if (item.idx === this.idx) {
          return true;
        }
      });

      this.list.splice(index, 1);
      this.llsit = this.list;
      // this.$router.go(-1);
      this.isShow = false;
    },
    onUpdate() {
      const begDate = this.$refs.rangendDate.getValue()[0];
      const endDate = this.$refs.rangendDate.getValue()[1];
      const date = { begDate, endDate, idx: this.idx };

      if (this.isDateError(this.list, date)) {
        return;
      }

      this.list.findIndex((item) => {
        if (item.idx === this.idx) {
          item.begDate = begDate;
          item.endDate = endDate;
          item.level = this.levelValue;

          return true;
        }
      });

      this.isShow = false;
      this.list = this.list;
    },
    onFinish() {
      const begDate = this.$refs.rangendDate.getValue()[0];
      const endDate = this.$refs.rangendDate.getValue()[1];
      const date = { begDate, endDate, level: this.levelValue, idx: this.idx };
   
      console.info(date.begDate)
      
      if (date.begDate == "" || date.begDate == null) {
        this.$toast.fail("请填写开始时间");
        return;
      }

      if (date.endDate == "" || date.endDate ==null ) {
        this.$toast.fail("请填写结束时间");
        return;
      }

      if (date.level == "") {
        this.$toast.fail("请选择缴费档次");
        return;
      }

      if (this.isDateError(this.list, date)) {
        return;
      }

      this.list.push(date);

      this.isShow = false;
      this.list = this.list;
    },
    actionSelect(idx) {
      this.clickItem = idx;
      const find = this.actions.find((i) => {
        return i.idx === idx;
      });

      this.levelName = find.name;
      this.levelValue = find.value;
    },
    ...mapGetters("calc", ["getAnnuityCompany"]),
    ...mapMutations("calc", ["setAnnuityCompany"]),
  },
};
</script>

<style scoped>
.item-box >>> .van-cell {
  padding: 0;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.row-box {
  display: flex;
  justify-content: space-between;
}

.row-box >>> .TitleItem__title--2DuKR {
  width: 150px;
}

.row-box >>> .van-field__control {
  width: 200px;
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.row-box span {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}


.time-box {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  color: #333333;
  margin-top: 8px;
}

.time-box div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-margin {
  margin: 0 0 0 12px;
}

.action-box {
  padding: 20px 6px 20px 36px;
  display: flex;
  flex-wrap: wrap;
}

.action-item__box {
  width: 88px;
  height: 38px;
  border: 1px solid #cccccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px 28px 0;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: #333333;
}

.clickBk {
  background: #658CF1;
  color: #ffffff;
}
</style>