<template>
  <div>
    <calc-page>
      <template slot="topBox">
        <round-add-container
          title="补缴间断时间和档次"
          @add="onAdd"
          :list="list"
        >
          <div class="center">
            <div v-for="(item, idx) in list" :key="idx">
              <div class="item-box" @click="onUpdate(item)">
                <div class="time-box">
                  <div>{{ item.begDate }}</div>
                  <span></span>
                  <div>{{ item.endDate }}</div>
                </div>
                <div class="row-box">
                  <title-item text="缴费档次"></title-item>
                  <div>{{ item.level * 100 + "%" }}</div>
                </div>
              </div>
              <van-divider />
            </div>
          </div>
        </round-add-container>
      </template>

      <template slot="footerBtn">
        <div class="btn-box" @click="onDivide">下一步</div>
      </template>
    </calc-page>
    <company-dialog ref="overlay"></company-dialog>
  </div>
</template>

<script>
import { Divider } from "vant";
import CalcPage from "@/components/CalcPage";
import TitleItem from "@/views/calc/TitleItem";
import { mapGetters, mapMutations } from "vuex";
import RoundAddContainer from "@/views/calc/components/RoundAddContainer";
import CompanyDialog from "./CompanyDialog";
import { baseFee } from "@/api/tool";
import to from "@/utils/to";

export default {
  components: {
    CalcPage,
    TitleItem,
    RoundAddContainer,
    [Divider.name]: Divider,
    CompanyDialog,
  },
  data() {
    return {
      newTest: [],
    };
  },
  computed: {
    list() {
      return this.getAnnuityCompany().gapList;
    },
  },
  methods: {
    onAdd() {
      this.$refs.overlay.open();
    },
    onUpdate(item) {
      this.$refs.overlay.open(item);
    },
    async onDivide() {
      if (this.list.length <= 0) {
        this.$showInfo("请先添加间断时间和档次");
        return;
      }
      this.newTest = [];
      this.list.forEach((item) => {
        let bYear = item.begDate.substr(0, 4);
        let eYear = item.endDate.substr(0, 4);
        let bMonth = item.begDate.substr(5, 7);
        // 判断当前时间段是否在同一年
        if (bYear === eYear) {
          let obj = {
            begDate: "",
            endDate: "",
            baseFee: "",
            level:""
          };
          // 判断开始时间月份是否是第一月
          if (bMonth !== "01") {
            obj.begDate = item.begDate;
          } else {
            obj.begDate = bYear + "/01";
          }
          obj.endDate = item.endDate;
          obj.level = item.level;
          this.newTest.push(obj);
        } else {
          //对开始到结束时间做循环
          for (let i = bYear; i <= eYear; i++) {
            let obj = {
              begDate: "",
              endDate: "",
              baseFee: "",
            };
            //开始时间年等于i，表示为开始时间到12月
            if (bYear == i) {
              obj.begDate = item.begDate;
              obj.endDate = bYear + "/12";
            } else if (eYear == i) {
              //结束时间年等于i，表示从1月到结束时间
              obj.begDate = i + "/01";
              obj.endDate = item.endDate;
            } else {
              //中间时间段从1月到12月
              obj.begDate = i + "/01";
              obj.endDate = i + "/12";
            }
            obj.level = item.level;
            this.newTest.push(obj);
          }
        }
      });
      this.sortByKey(this.newTest, "begDate");

      //初始化基数
      console.info(this.newTest)
      const baseFeeData = JSON.parse(JSON.stringify(this.newTest));
      baseFeeData.forEach((item) => {
        item.begDate = item.begDate + "/01";
        item.endDate = item.endDate + "/01";
      });
      let [err, res] = await to(baseFee(baseFeeData));
      if (err) {
        this.$toast.fail("获取缴费基数失败");
        return;
      }
      this.newTest.forEach((obj) => {
        res.forEach((baseFeeInfo) => {
          if (
            baseFeeInfo.begDate === obj.begDate+"/01" &&
            baseFeeInfo.endDate === obj.endDate+"/01"
          ) {
            obj.baseFee = baseFeeInfo.baseFee+"";
          }
        });
      });

      this.setAnnuityCompany({ dividedGapList: this.newTest });
      this.$router.push({ name: "PensionSupplementCompanyDividePage" });
    },
    sortByKey(array, key) {
      return array.sort(function (a, b) {
        let x = a[key];
        let y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    ...mapGetters("calc", ["getAnnuityCompany"]),
    ...mapMutations("calc", ["setAnnuityCompany"]),
  },
};
</script>

<style scoped>
.top-btn {
  height: 50px;
  background: #dbf0ff;
  border-radius: 12px 12px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 22px;
  color: #658CF1;
}

.img-box {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.center {
  min-height: 100px;
}

.center >>> .van-divider {
  margin: 0;
}

.row-box {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #333333;
}

.time-box {
  display: flex;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #333333;
  align-items: center;
}

.time-box span {
  width: 16px;
  height: 2px;
  background: #999999;
  border-radius: 1px;
  margin: 0 4px;
}


.btn-box {
  position: fixed;
  bottom: 10px;
  background: #658CF1;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: #ffffff;
  display: flex;
  padding: 10px 152px;
}
</style>
